const hexaPatternDivisions = {divisions: [
    [1],
    [2],
    [2,1],
    [1,2,1],
    [1,2,2],
    [2,2,2],
    [2,2,3],
    [3,2,3],
    [3,2,3,1],
    [1,3,2,3,1],
    [1,3,2,3,2],
    [2,3,2,3,2],
    [2,3,2,3,2,1],
    [1,2,3,2,3,2,1],
    [1,2,3,2,3,2,3],
    [1,3,3,2,3,3,1],
    [1,3,3,2,3,3,2],
    [2,3,3,2,3,3,2],
    [2,3,3,2,3,3,3],
    [1,2,3,3,2,3,3,2,1],
    [3,3,3,2,3,3,4],
    [4,3,3,2,3,3,4],
],
stylings: [
    [0],
    [0],
    [0,1],
    [1,0,1],
    [1,0,1],
    [1,0,1],
    [0,1,0],
    [0,1,0],
    [0,1,0,2],
    [2,0,1,0,2],
    [2,0,1,0,2],
    [1,0,1,0,1],
    [1,0,1,0,1,2],
    [2,1,0,1,0,1,2],
    [2,1,0,1,0,1,0],
    [2,1,0,1,0,1,2],
    [2,1,0,1,0,1,2],
    [2,1,0,1,0,1,2],
    [2,1,0,1,0,1,2],
    [1,0,1,0,1,0,1,0,1],
    [0,1,0,1,0,1,0],
    [0,1,0,1,0,1,0],
]}

export default hexaPatternDivisions;